select {
    font-size: 80% !important;
    border-radius: 25px !important;
    border: 0px solid #e5e5e5 !important;
    outline: 0px !important;
    padding-left: 10px !important;
    overflow: hidden !important;
    width: fit-content !important;
}

.editorButton {
    border: 0px !important;
    outline: 0px !important;
    background-color: white !important;
    background: white !important;
}

.RichTextEditor__root___2QXK- {
    border: 0px !important;
    outline: 0px !important;
    border-radius: 50px;
}

.IconButton__root___3tqZW {
    background-color: white !important;
    background: white !important;
}

.IconButton__isActive___2Ey8p {
    background-color: #e5e5e5 !important;
}

.RichTextEditor__editor___1QqIU {
    height: 100% !important;
    font-family: "Open Sans", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 0px !important;
    margin: 0px !important;
}

.EditorToolbar__root___3_Aqz {
    padding: 0px !important;
    margin: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 10px !important;
    display: flex;
    justify-content: flex-start;
    border: 0px !important;
}

.public-DraftEditorPlaceholder-root {
    padding: 0px !important;
    margin: 0px !important;
    padding-top: 20px !important;
}

.public-DraftEditor-content {
    padding: 0px !important;
    margin: 0px !important;
    padding-top: 20px !important;
}

