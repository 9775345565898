a:link {
  color: white;
  text-decoration: none;
}

a:visited {
  color: white;
}

a:hover {
  color: white;
  text-size-adjust: 100%;
}

.bluelink {
  color: #7b3fe4 !important;
  cursor: pointer;
}

.bluelink:hover {
  color: #8c4ef7 !important;
}

.buttonlink:hover {
  background-color: transparent !important;
}

.bluebutton {
  border-radius: 50px;
  padding: 12.5px;
  background: #a675ff !important;
  color: white;
  font-weight: bold;
  font-size: 80%;
  padding-left: 25px;
  padding-right: 25px;
}

.bluebutton:hover {
  background: #af87f9 !important;
  color: white !important;
}

.bluebuttonhollow {
  border-radius: 50px;
  padding: 12.5px;
  background: transparent !important;
  border: 2px solid #7b3fe4;
  color: #7b3fe4;
  font-weight: bold;
  font-size: 80%;
}

.bluebuttonhollow:hover {
  border: 2px solid #8c4ef7;
  color: #8c4ef7 !important;
  background: transparent !important;
}

.whitebutton {
  border-radius: 50px;
  padding: 12.5px;
  background: white;
  border: 2px solid white;
  color: #7b3fe4;
  font-weight: bold;
  font-size: 80%;
}

.whitebutton:hover {
  border: 2px solid #c0c0c0;
  background: #c0c0c0;
}

.whitebuttonhollow {
  border-radius: 50px;
  padding: 10px;
  background: transparent;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 70%;
}

.whitebuttonhollow:hover {
  background: transparent !important;
}

.blackbuttonhollow {
  border-radius: 50px;
  padding: 10px;
  background: transparent !important;
  border: 2px solid black;
  color: black;
  font-weight: bold;
  font-size: 70%;
}

.blackbuttonhollow:hover {
  color: black !important;
}

.whitebuttonhollow:hover {
  border: 2px solid #e5e5e5;
  color: #e5e5e5;
  background: transparent !important;
}

.headingbutton {
  border-radius: 50px;
  border: 1px solid #808080;
  font-size: 80%;
  padding: 7px;
  color: #808080;
  margin-bottom: 1%;
  cursor: none;
  background-color: #1b1b1b;
  font-weight: bold;
}

.headerbutton {
  border-radius: 50px;
  padding: 12.5px;
  background: transparent;
  color: black;
}

.headerbutton:hover {
  border: 2px solid #f4b962;
  background: #f4b962;
  color: #111111;
}

.mobileheadingbutton {
  border-radius: 50px;
  border: 1px solid #f4b962;
  font-size: 100%;
  padding: 7px;
  color: #f4b962;
  margin-bottom: 1%;
  cursor: none;
  background-color: #111111;
  font-weight: bold;
}

.composebutton {
  border-radius: 50px;
  color: black !important;
  background: transparent !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 80%;
  padding-right: 7.5%;
  padding-left: 7.5%;
}

.composebutton:hover {
  background: transparent !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 4px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  font-size: 80%;
  padding-right: 7.5%;
  padding-left: 7.5%;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #e5e5e5;

}

.shadowDark {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: 1px solid #202020;

}