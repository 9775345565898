.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 12.5px;
  font-size: 80%;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-top: 0px;
  font-weight: bold;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  padding: 5%;
  text-decoration: none;

}

.dropdown-content a:hover {
  color: #ffffff;
  background-color: #a675ff;
}



.dropdownbtn {
  border-radius: 12.5px;
  color: black !important;
  background: #fff !important;
  font-size: 80%;
  padding-right: 7.5%;
  padding-left: 7.5%;
}

.dropdownbtndark {
  border-radius: 12.5px;
  color: black !important;
  background: #202020 !important;
  font-size: 80%;
  padding-right: 7.5%;
  padding-left: 7.5%;
}

.dropdownbtn:hover {}