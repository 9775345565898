.snackbar {
    visibility: hidden;
    min-width: 300px;
    background-color: #F3F4F6;
    color: #fff;
    border-radius: 12.5px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    right: 5%;
    top: 10%;
    border: 1px solid #E5E7EB;
    /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */
}

.snackbar.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.snackbar.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms
}