.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    min-width: 120px;
    background-color: #F3F4F6;
    color: #000;
    text-align: center;
    border-radius: 50px;
    padding: 5px 0;
    border: 1px solid #E5E7EB;
    font-weight: 600;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -60px; 
    top: 125%;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }


  h1 { 
    display: block !important;
    font-size: 2em !important;
    margin-top: 0.67em !important;
    margin-bottom: 0.67em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}
h2 {
    display: block !important;
    font-size: 1.5em !important;
    margin-top: 0.83em !important;
    margin-bottom: 0.83em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}
h3 { 
    display: block !important;
    font-size: 1.17em !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}
h4 { 
    display: block !important;
    margin-top: 1.33em !important;
    margin-bottom: 1.33em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}
h5 { 
    display: block !important;
    font-size: .83em !important;
    margin-top: 1.67em !important;
    margin-bottom: 1.67em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}
h6 { 
    display: block !important;
    font-size: .67em !important;
    margin-top: 2.33em !important;
    margin-bottom: 2.33em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}